.example {
    display: flex !important;
    flex-wrap: wrap !important;
}
.item {
    position: relative;
    border: 1px solid #ccc;
    opacity: 1;
    display: flex;
    flex-direction: column;
    margin-top: 2vw;
    margin-left: 1vw;
    margin-right: 1vw;
}

.item:hover {
    cursor: grab;
}

.hovered .item {
    opacity: 0.5;
}

.item img {
    width: 15vw;
}

.item p {
    margin: 0;
    padding: 0;
    text-align: center;
}

.item .delete {
    position: absolute;
    fill: red;
    background-color: #ccc;
    width: 1.5vw;
    height: 1.5vw;
}

.item .delete:hover {
    cursor: pointer;
}

.finalButton {
    margin: auto;
    text-align: center;
    margin-top: 40px;
}

.exercice {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 10vw;
    margin-left: 2vw;
    margin-right: 2vw;
}

.exercices {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 50px;
}

.exercice img {
    width: 10vw;
}

.arrows {
    width: 2vw;
    height: 5vw;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

.arrow {
    position: absolute;
    margin-top: 4.5vw;
    margin-left: -0.48vw;
}

.edit {
    position: absolute;
    right: 20px;
    top: 20px;
}

.up{
    position: absolute;
    right: 130px;
    top: 20px;
}

.down{
    position: absolute;
    right: 95px;
    top: 20px;
}

.kill {
    display: inherit;
}

.add {
    position: absolute;
    right: 20px;
    top: 10px;
}

.add svg {
    width: 1.2vw;
    height: 1.2vw;
}